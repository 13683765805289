@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

// Variables
$bg-body: #f9f9f9;

$red: #005A9C; //#4472ca; //0080bf
$blue: #2b2e48;
$primary-color: $red;
$secondary-color: $blue;


$base-font-color: #726f77;

// Timeline
$timeline-color: $primary-color;


// Mixins and Placeholders
%clearfix {

  &:after,
  &:before {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

@mixin prefix($prop, $val) {

  @each $prefix in '-webkit-',
  '-moz-',
  '-ms-',
  '' {
    #{$prefix}#{$prop}: $val;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

// body,
// html {
//   height: 100%;
// }

// body {
//   background: $bg-body;
//   background-size: cover;
//   margin: 0;
//   padding: 0;
//   // font-family: $base-font;
//   line-height: 20px;
//   font-size: 14px;
//   color: $base-font-color;
// }

// img {
//   max-width: 100%;
// }

a {
  text-decoration: none;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}


.project-name {
  text-align: center;
  padding: 10px 0;
}

// Header
header {
  background: $secondary-color;
  padding: 10px;
  @include prefix(box-shadow, 0 3px 3px rgba(0, 0, 0, 0.05));
  @extend %clearfix;

  .logo {
    color: $primary-color;
    float: left;


    >span {
      color: lighten($primary-color, 20%);
      font-weight: 300;
    }
  }

  .social {
    float: right;

    .btn {
      font-family: "Dosis";
      font-size: 14px;
      margin: 10px 5px;
    }
  }
}


// Timeline
#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0 10px;
  @include prefix(transition, all .4s ease);

  &:before {
    content: "";
    width: 3px;
    height: 100%;
    background: $timeline-color;
    left: 50%;
    top: 0;
    position: absolute;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
    width: 100%;
  }

  .timeline-item {
    margin-bottom: 50px;
    position: relative;
    @extend %clearfix;

    .timeline-icon {
      background: $timeline-color;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      left: 50%;
      overflow: hidden;
      margin-left: -23px;
      @include prefix(border-radius, 50%);
      &.active {
        background: lighten($timeline-color, 20%); // Change color on scroll
      }
      transition: background-color 0.2s ease-in-out;

      svg {
        position: relative;
        top: 14px;
        left: 14px;
      }
    }

    .timeline-content {
      width: 45%;
      background: #fff;
      padding: 20px;
      @include prefix(box-shadow, 0 3px 0 rgba(0, 0, 0, 0.1));
      @include prefix(border-radius, 5px);
      @include prefix(transition, all .3s ease);

      h2 {
        font-weight: bold;
        margin-bottom: 1.8rem;
        padding: 15px;
        background: $timeline-color;
        color: #fff;
        margin: -20px -20px 0 -20px;
        // font-weight: 300;
        @include prefix(border-radius, 3px 3px 0 0);
      }

      h3 {
        font-size: medium;
        font-weight: bold;
        margin-bottom: 0.8rem;
        padding: 8px;
        background: $timeline-color;
        color: lightblue;
        margin: -20px -20px 0 -20px;
        transition: all 0.2s ease-in;
        @include prefix(border-radius, 3px 3px 0 0);
      }

      h3.visible {
        opacity: 1;
        transform: translateY(0);
      }

      p {
        margin-top: 1em;
      }

      &:before {
        content: '';
        position: absolute;
        left: 45%;
        top: 20px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid $timeline-color;
      }

      &.right {
        float: right;

        &:before {
          content: '';
          right: 45%;
          left: inherit;
          border-left: 0;
          border-right: 7px solid $timeline-color;
        }
      }
    }
  }
}

// Buttons
.btn {
  padding: 5px 15px;
  text-decoration: none;
  background: transparent;
  border: 2px solid lighten($primary-color, 10%);
  color: lighten($primary-color, 10%);
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  @include prefix(border-radius, 5px);
  @include prefix(transition, background .3s ease);
  @include prefix(box-shadow, 2px 2px 0 lighten($primary-color, 10%));
}

.text-box {
  padding: 10px 20px;
  background: transparent;
  border: 2px solid lighten($primary-color, 10%);
  color: lighten($primary-color, 10%);
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  @include prefix(border-radius, 5px);
  @include prefix(transition, all .3s ease);
  @include prefix(box-shadow, 2px 2px 0 lighten($primary-color, 10%));
}


@media screen and (max-width: 768px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;

    &:before {
      left: 0;
    }

    .timeline-item {
      .timeline-content {
        width: 90%;
        float: right;

        &:before,
        &.right:before {
          left: 10%;
          margin-left: -6px;
          border-left: 0;
          border-right: 7px solid $timeline-color;
        }
      }

      .timeline-icon {
        left: 0;
      }
    }
  }
}