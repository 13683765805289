@import "https://fonts.googleapis.com/css?family=Montserrat:400,700";
#timeline .timeline-item:after, header:after, #timeline .timeline-item:before, header:before {
  content: "";
  clear: both;
  width: 100%;
  display: block;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

.project-name {
  text-align: center;
  padding: 10px 0;
}

header {
  -ms-box-shadow: 0 3px 3px #0000000d;
  background: #2b2e48;
  padding: 10px;
  box-shadow: 0 3px 3px #0000000d;
}

header .logo {
  color: #005a9c;
  float: left;
}

header .logo > span {
  color: #0394ff;
  font-weight: 300;
}

header .social {
  float: right;
}

header .social .btn {
  margin: 10px 5px;
  font-family: Dosis;
  font-size: 14px;
}

#timeline {
  width: 100%;
  margin: 30px auto;
  padding: 0 10px;
  transition: all .4s;
  position: relative;
}

#timeline:before {
  content: "";
  background: #005a9c;
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

#timeline:after {
  content: "";
  clear: both;
  width: 100%;
  display: table;
}

#timeline .timeline-item {
  margin-bottom: 50px;
  position: relative;
}

#timeline .timeline-item .timeline-icon {
  -ms-border-radius: 50%;
  background: #005a9c;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: -23px;
  transition: background-color .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
}

#timeline .timeline-item .timeline-icon.active {
  background: #0394ff;
}

#timeline .timeline-item .timeline-icon svg {
  position: relative;
  top: 14px;
  left: 14px;
}

#timeline .timeline-item .timeline-content {
  -ms-box-shadow: 0 3px 0 #0000001a;
  -ms-border-radius: 5px;
  background: #fff;
  border-radius: 5px;
  width: 45%;
  padding: 20px;
  transition: all .3s;
  box-shadow: 0 3px #0000001a;
}

#timeline .timeline-item .timeline-content h2 {
  color: #fff;
  -ms-border-radius: 3px 3px 0 0;
  background: #005a9c;
  border-radius: 3px 3px 0 0;
  margin: -20px -20px 0;
  padding: 15px;
  font-weight: bold;
}

#timeline .timeline-item .timeline-content h3 {
  color: #add8e6;
  -ms-border-radius: 3px 3px 0 0;
  background: #005a9c;
  border-radius: 3px 3px 0 0;
  margin: -20px -20px 0;
  padding: 8px;
  font-size: medium;
  font-weight: bold;
  transition: all .2s ease-in;
}

#timeline .timeline-item .timeline-content h3.visible {
  opacity: 1;
  transform: translateY(0);
}

#timeline .timeline-item .timeline-content p {
  margin-top: 1em;
}

#timeline .timeline-item .timeline-content:before {
  content: "";
  border-top: 7px solid #0000;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #005a9c;
  width: 0;
  height: 0;
  position: absolute;
  top: 20px;
  left: 45%;
}

#timeline .timeline-item .timeline-content.right {
  float: right;
}

#timeline .timeline-item .timeline-content.right:before {
  content: "";
  right: 45%;
  left: inherit;
  border-left: 0;
  border-right: 7px solid #005a9c;
}

.btn {
  color: #0077cf;
  text-transform: uppercase;
  -ms-border-radius: 5px;
  -ms-box-shadow: 2px 2px 0 #0077cf;
  background: none;
  border: 2px solid #0077cf;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 12px;
  text-decoration: none;
  transition: background .3s;
  display: inline-block;
  position: relative;
  box-shadow: 2px 2px #0077cf;
}

.text-box {
  color: #0077cf;
  text-align: left;
  -ms-border-radius: 5px;
  -ms-box-shadow: 2px 2px 0 #0077cf;
  background: none;
  border: 2px solid #0077cf;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.5;
  transition: all .3s;
  display: inline-block;
  position: relative;
  box-shadow: 2px 2px #0077cf;
}

@media screen and (width <= 768px) {
  #timeline {
    width: 90%;
    margin: 30px;
    padding: 0;
  }

  #timeline:before {
    left: 0;
  }

  #timeline .timeline-item .timeline-content {
    float: right;
    width: 90%;
  }

  #timeline .timeline-item .timeline-content:before, #timeline .timeline-item .timeline-content.right:before {
    border-left: 0;
    border-right: 7px solid #005a9c;
    margin-left: -6px;
    left: 10%;
  }

  #timeline .timeline-item .timeline-icon {
    left: 0;
  }
}
/*# sourceMappingURL=index.7cab8612.css.map */
